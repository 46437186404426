import React, { Component } from "react";
import classnames from "classnames";

class NewsletterForm extends Component {
  constructor(props) {
    super(props);
    this.state = { status: "" };
  }

  submitForm = (ev) => {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  };

  render() {
    const { className, submit = "Submit" } = this.props;
    const { status } = this.state;

    return (
      <form
        className={classnames(
          "newsletter-form field field-grouped is-revealing",
          className
        )}
        onSubmit={this.submitForm}
        action="https://formspree.io/f/xgepqwpl"
        method="POST"
      >
        <div className="control control-expanded">
          <input
            className="input"
            type="email"
            name="email"
            placeholder="Your best email&hellip;"
          />
        </div>
        <div className="control">
          {status === "SUCCESS" ? (
            <p style={{ marginTop: "8px" }}>Thanks!</p>
          ) : (
            <button
              className="button button-primary button-block button-shadow"
              type="submit"
            >
              {submit}
            </button>
          )}
        </div>
        {status === "ERROR" && <p>Ooops! There was an error.</p>}
      </form>
    );
  }
}

export default NewsletterForm;
