import React from "react";

const year = new Date().getFullYear();

export default function Footer() {
  return (
    <footer className="site-footer text-light">
      <div className="container">
        <div className="site-footer-inner">
          {/* <NavLinks className="footer-links" />
					<SocialLinks className="footer-social-links" /> */}
          <div className="footer-copyright">
            &copy; {year} Projoro, all rights reserved
          </div>
        </div>
      </div>
    </footer>
  );
}
