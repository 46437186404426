import React from 'react';
import Layout from '../layouts/index';
import Hero from '../components/hero/Hero';
import HeroIllustration from '../components/hero/HeroIllustration';

export default function IndexPage() {
	return (
		<Layout>
			<Hero
				title="Projoro"
				content="Optimization software for your day-to-day life. Subscribe to get notified of our newest releases."
				illustration={HeroIllustration}
			/>
		</Layout>
	);
}
